import React from 'react'
import { Box, Container } from 'theme-ui'
import { Layout, Stack, Main, Hero, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import BannerHorizontal from '@widgets/BannerHorizontal'
import Categories from '@widgets/Categories'
import { useBlogCategories } from '@helpers-blog'

const heroMarginX = 'auto'
const dotsHeight = '2.25rem'

const middleBottomPosition = `calc(${dotsHeight} * 0.5)`
const belowImagePosition = `calc(-1 * ${dotsHeight}) !important`

const styles = {
  hero: {
    position: `relative`,
    maxWidth: '75rem',
    marginLeft: heroMarginX,
    marginRight: heroMarginX,
    '& .slick-dots': {
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: [middleBottomPosition, null, belowImagePosition],
      position: 'absolute',
      top: 'auto !important'
    }
  }
}

const Posts = ({ data: { featuredPosts = {}, posts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const sliderRef = React.useRef()
  const categories = useBlogCategories()

  const renderGroup = (group, index) => {
    return (
      <React.Fragment key={`${group.categoryName}.list`}>
        <Stack
          title={group.categoryName}
          titleLink={group.nodes[0].category.slug}
        >
          <Main>
            <CardList
              nodes={group.nodes}
              limit={6}
              columns={[1, 1, 2, 3]}
              variant={['vertical']}
              omitCategory
            />
            <Divider space={2} />
          </Main>
        </Stack>
        {index === 0 && (
          <>
            <Divider />
            <Stack effectProps={{ effect: false }}>
              <BannerHorizontal />
            </Stack>
          </>
        )}
        {index !== posts.group.length - 1 && <Divider />}
      </React.Fragment>
    )
  }

  const getIfGroupHasName = name => group => {
    return group.categoryName === name
  }

  const findGroupByName = name => {
    return posts.group.find(getIfGroupHasName(name))
  }

  const getGroupWithAddedGroup = (renderedGroups, group, index) => {
    const renderedGroup = renderGroup(group, index)
    return [...renderedGroups, renderedGroup]
  }

  const findAndAddGroup = (renderedGroups, name) => {
    const group = findGroupByName(name)
    if (group) {
      return getGroupWithAddedGroup(
        renderedGroups,
        group,
        renderedGroups.length
      )
    }
    return renderedGroups
  }

  const renderCategories = () => {
    const groupNames = ['News', 'Guides', 'Analysis', 'Survey']
    return groupNames.reduce(findAndAddGroup, [])
  }

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {props.location.pathname === basePath && (
        <>
          <Box sx={{ display: [`none`, `block`] }}>
            <Divider space={3} />
            <Container>
              <Categories
                categories={categories}
                variant='horizontal'
                omitTitle
              />
            </Container>
            <Divider space={3} />
          </Box>
          <Hero full sx={styles.hero}>
            <CardList
              nodes={featuredPosts.nodes}
              limit={3}
              variant='horizontal-cover-hero'
              omitFooter
              omitCategory
              slider
              autoPlay
              fade
              dots
              arrows={false}
              ref={sliderRef}
              loading='eager'
              controlPosition='bottom'
            />
          </Hero>
          <Divider />
          {renderCategories()}
        </>
      )}
      <Divider />
      <PreFooter>
        {services.mailchimp && (
          <>
            <Divider />
            <NewsletterExpanded simple />
          </>
        )}
      </PreFooter>
    </Layout>
  )
}

export default Posts
